<template>
  <b-container fluid>
    <b-row>
      <div class="col-sm-12">
          <div class="iq-card position-relative inner-page-bg bg-primary" style="height: 150px;">
            <div class="inner-page-title">
                <h3 class="text-white">Font Awwsome</h3>
                <p class="text-white">lorem ipsum</p>
            </div>
          </div>
      </div>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Fontawesome Icon</h4>
          </template>
          <template v-slot:body>
            <p>Use Class <code>&lt;i class="fa fa-address-book"&gt;&lt;/i&gt;</code></p>
            <b-row class="mt-3">
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-address-book" aria-hidden="true"></i>address-book</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-envelope-open" aria-hidden="true"></i>envelope-open</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-id-card" aria-hidden="true"></i>id-card</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-telegram" aria-hidden="true"></i>telegram</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-user-circle" aria-hidden="true"></i>user-circle</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-area-chart" aria-hidden="true"></i>area-chart</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-asterisk" aria-hidden="true"></i>asterisk</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-car" aria-hidden="true"></i>car</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-bars" aria-hidden="true"></i>bars</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-battery-full" aria-hidden="true"></i>battery-full</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-bluetooth" aria-hidden="true"></i>bluetooth</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-book" aria-hidden="true"></i>book</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-bug" aria-hidden="true"></i>bug</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-building" aria-hidden="true"></i>building</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-calculator" aria-hidden="true"></i>calculator</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-calendar" aria-hidden="true"></i>calendar</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-camera" aria-hidden="true"></i>camera</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-commenting" aria-hidden="true"></i>commenting</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-crop" aria-hidden="true"></i>crop</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-download" aria-hidden="true"></i>download</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-folder" aria-hidden="true"></i>folder</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-gift" aria-hidden="true"></i>gift</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-users" aria-hidden="true"></i>users</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-hashtag" aria-hidden="true"></i>hashtag</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-home" aria-hidden="true"></i>home</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-lock" aria-hidden="true"></i>lock</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-graduation-cap" aria-hidden="true"></i>graduation-cap</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-paper-plane" aria-hidden="true"></i>paper-plane</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-star" aria-hidden="true"></i>star</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-tag" aria-hidden="true"></i>tag</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-trash" aria-hidden="true"></i>trash</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-upload" aria-hidden="true"></i>upload</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-university" aria-hidden="true"></i>university</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-wifi" aria-hidden="true"></i>wifi</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-thumbs-up" aria-hidden="true"></i>thumbs-up</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-train" aria-hidden="true"></i>train</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-file" aria-hidden="true"></i>file</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-snapchat" aria-hidden="true"></i>snapchat</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-twitter" aria-hidden="true"></i>twitter</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-wordpress" aria-hidden="true"></i>wordpress</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-wordpress" aria-hidden="true"></i>wordpress</b-link>
              </b-col>
              <b-col lg="2" md="2" sm="2">
                <b-link class="iq-icons-list" href="#"><i class="fa fa-wordpress" aria-hidden="true"></i>wordpress</b-link>
              </b-col>
              <b-col sm="12" class="text-center mt-3">
                <b-link href="https://fontawesome.com/v4.7.0/" target="blank" class="btn btn-primary">View All Icon</b-link>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { socialvue } from '../../config/pluginInit'
export default {
  name: 'IconFontAwesome5',
  mounted () {
    socialvue.index()
  }
}
</script>
